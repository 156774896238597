'format es6';
'use strict';

import $ from 'jquery';
import ns from 'ns';
import Promise from 'Promise';

// Import librairies here
import gsap, { TweenMax } from 'gsap';

import { lockBody, unlockBody } from './utils/lockBody';

import { PaneHelper } from './Panable';

export const MOBILE_TRESHOLD = 991;
const $window = $(window);
const noop = () => {};
const NICE_EASE = gsap.CustomEase.create('custom', '0.4, 0, 0.2, 1');

let isMobile = $window.width() <= MOBILE_TRESHOLD;

const DOM_DELTA_LINE = 0x01;

const doc = $(document);

let DEFAULT_INDEX = 0;
let gallery;
let galleryItem;
let galleryImage;
let slider;
let thumbnails;
let thumbnailsInner;
let thumbnailsCtn;
let intro;
let introCTA;
let scrubber;
let scrubHandle;

let hiresTimeout = null;
let wheelTimeout = null;

let scrubNext;
let scrubPrev;

let currentGalleryIndex = DEFAULT_INDEX;

let photos = {};
let orderedPhotos = [];
let cancelInertia = false;

let singleThumbHeight = 0;
let singleThumbWidth = 0;
let scrubH = 0;
let thumbsHeight = 0;
let thumbsWidth = 0;

let thumbsLastTouch = null;
let thumbsInitialTouch = null;

const scrollPosition = {
	x: 0,
	y: 0,
};
let scrollTick = false;

export const GalleryHelper = {

	setSocialMetas() {
	},

	/**
	 * Sets the History state to its default value.
	 */
	setDefaultState() {
		window.history.replaceState(null, '', GalleryHelper.getUrl());

		// console.log('State was set to ', window.history.state);
	},

	/**
	 * Sets the History state to match the id of a gallery item from it's index.
	 * 
	 * @param {Number} index 
	 */
	setGalleryState() {
		const i = galleryItem.data('gallery-id');
		window.history.pushState({ i }, `Photo : ${i}`, GalleryHelper.getUrl(i));

		// console.log('State was set to ', window.history.state);
	},

	/**
	 * Gets the url for the given index. Defaults to the current gallery index.
	 * 
	 * @param {Number} index Default is the current gallery index.
	 * @returns {String}
	 */
	getUrl(index = '') {
		return `${ns.gallery_url}/${index}`; 
	},

	/**
	 * Gets the current index of the gallery. Used for outside checking.
	 * 
	 * @returns {Number} currentGalleryIndex
	 */
	getGalleryIndex() {
		return currentGalleryIndex;
	},

	getIdFromDirection(direction) {
		const id = orderedPhotos[orderedPhotos.indexOf(currentGalleryIndex) + direction];
		return id;
	},

	canGoTo(direction) {
		return Boolean(GalleryHelper.getIdFromDirection(direction));
	},

	goToClosest(offset = 0) {
		let prc = 0;
		if (!isMobile) {
			prc = (-(scrollPosition.y + offset) - (singleThumbHeight / 2)) / thumbsHeight;
		} else {
			prc = (-(scrollPosition.y + offset) - (singleThumbWidth / 2)) / thumbsWidth;
		}
		let closest = Math.ceil(prc * thumbnails.length);

		if (closest <= 0) closest = 0;
		if (closest >= thumbnails.length - 1) closest = thumbnails.length - 1;
		// closest = Math.max(0, Math.min(closest, thumbnails.length));

		this.scrollToId(orderedPhotos[closest]);
	},

	updateTween() {
		if (scrollTick) {
			return;
		}
		scrollTick = true;

		// if (scrollPosition.y >= 0) scrollPosition.y = 0;
		// if (scrollPosition.y <= -thumbsHeight) scrollPosition.y = -(thumbsHeight - singleThumbHeight);

		const prc = (-scrollPosition.y + (singleThumbHeight / 2)) / thumbsHeight;
		const scrubPosition = prc * scrubH;

		showGalleryPhoto(orderedPhotos[Math.floor(prc * thumbnails.length)]);

		requestAnimationFrame(() => {
			if (isMobile) {
				scrubHandle[0].style.transform = `translate3d(${scrubPosition}px, 0, 0)`;
				thumbnailsInner[0].style.transform = `translate3d(${scrollPosition.y}px, 0, 0)`;
			} else {
				scrubHandle[0].style.transform = `translate3d(0, ${scrubPosition}px, 0)`;
				thumbnailsInner[0].style.transform = `translate3d(0, ${scrollPosition.y}px, 0)`;
			}

			scrollTick = false;
		});
	},

	scrub(pos, t = 0.9, onUpdate = noop, onComplete = noop) {
		// cancelInertia = true;
		// console.log('cancel');
		TweenMax.killTweensOf(scrollPosition);
		TweenMax.to(scrollPosition, t, {
			y: -Math.ceil(pos * (thumbsHeight - singleThumbHeight)),
			ease: NICE_EASE,
			onUpdate: this.updateTween,
			onComplete,
		});
	},

	/**
	 * Goes to the given index. Different behavior on mobile than
	 * on desktop, since on mobile we use a slider and on desktop 
	 * a scrollable gallery.
	 * 
	 * @param {Number} targetIndex The desired item's index
	 * @param {Number} t Optional. Animation duration
	 * 
	 * @returns {Promise}
	 */
	scrollToId(id, t = 0.9, onUpdate = noop, onComplete = noop) {
		// cancelInertia = true;
		// console.log('cancel');
		return new Promise((resolve) => {
			const offset = -orderedPhotos.indexOf(id) * (isMobile ? singleThumbWidth : singleThumbHeight);
			TweenMax.killTweensOf(scrollPosition);
			const props = {
				y: offset,
				ease: NICE_EASE,
				onUpdate: () => {
					onUpdate();
					this.updateTween();
				},
				onComplete: () => {
					resolve();
					if (t === 0) {
						onUpdate();
						this.updateTween();
					}
					onComplete();
				},
			};
			TweenMax.to(scrollPosition, t, props);
		});
	},

	scrollByOffset(offset) {
		// cancelInertia = true;
		// console.log('cancel');
		scrollPosition.y += offset;

		if (-scrollPosition.y < 0) {
			scrollPosition.y = 0;
		} else if (!isMobile && -scrollPosition.y > thumbsHeight - singleThumbHeight) {
			scrollPosition.y = -(thumbsHeight - singleThumbHeight);
		} else if (isMobile && -scrollPosition.y > thumbsWidth - singleThumbWidth) {
			scrollPosition.y = -(thumbsWidth - singleThumbWidth);
		}
		// console.log(scrollPosition.y);

		this.updateTween();
	},
};

function hideIntro() {
	intro.removeClass('active');
}

function showIntro() {
	intro.addClass('active');
}


function loadHiRes(photo) {
	const img = galleryItem.find('[data-image]');
	img.off('load.gallery').on('load.gallery', () => {
		img.css('visibility', '');
		galleryItem.find('[data-infos]').removeClass('scrubbing');
		TweenMax.fromTo(img, 0.1, { opacity: 0 }, { opacity: 1 });
	});
	img.attr('src', photo.image);
}

function showGalleryPhoto(id) {
	if (currentGalleryIndex !== id) {
		const photo = photos[id];
		if (!photo) return;

		currentGalleryIndex = id;

		if (hiresTimeout) {
			clearTimeout(hiresTimeout);
		}
		hiresTimeout = setTimeout(() => {
			loadHiRes(photo);
		}, 200);

		galleryItem.data('gallery-id', id);
		galleryItem.find('[data-pane-id]').data('pane-id', id);
		galleryItem.find('[data-infos]').addClass('scrubbing');
		galleryItem.find('[data-low-res]').attr('src', photo.thumb);
		galleryItem.find('[data-image]').css('visibility', 'hidden');
		galleryItem.find('[data-title]').text(photo.title);
		let num = (orderedPhotos.length - 1) - orderedPhotos.indexOf(id);
		if (num === 0) num = '';
		else num = `Jour ${num}`;
		galleryItem.find('[data-location]').text(num);
		
		resizeGalleryItem();
	}
}

function goIfPossible(direction) {
	const curIndex = orderedPhotos.indexOf(currentGalleryIndex);
	const nextIndex = orderedPhotos[curIndex + direction];

	if (!nextIndex) {
		return;
	}

	GalleryHelper.scrollToId(nextIndex, 0.3);
}

function inertia(speed) {
	const newSpeed = speed * 0.91;

	scrollPosition.y += newSpeed;

	if (cancelInertia || Math.abs(speed) <= 0.3) {
		GalleryHelper.goToClosest();
		cancelInertia = false;
		return;
	}

	GalleryHelper.updateTween();
	requestAnimationFrame(() => {
		inertia(newSpeed);
	});
}

function onThumbnailsTouchMove(evt) {
	// evt.preventDefault();
	TweenMax.killTweensOf(scrollPosition);

	const offset = (isMobile) ?
		thumbsLastTouch.x - evt.touches[0].pageX :
		thumbsLastTouch.y - evt.touches[0].pageY;

	GalleryHelper.scrollByOffset(-offset);

	thumbsLastTouch = {
		x: evt.touches[0].pageX,
		y: evt.touches[0].pageY,
		last: Date.now(),
		speed: {
			x: (evt.touches[0].pageX - thumbsLastTouch.x),
			y: (evt.touches[0].pageY - thumbsLastTouch.y),
		},
	};
}

function onThumbnailsTouchEnd() {
	doc.off('touchmove.gallery');
	doc.off('touchend.gallery');

	if (thumbsLastTouch.x === thumbsInitialTouch.x && thumbsLastTouch.y === thumbsInitialTouch.y) {
		return;
	}

	requestAnimationFrame(() => {
		inertia(isMobile ? thumbsLastTouch.speed.x : thumbsLastTouch.speed.y);
	});
}

function onThumbnailsTouchStart(e) {
	e.preventDefault();

	thumbsLastTouch = {
		x: e.touches[0].pageX,
		y: e.touches[0].pageY,
		last: Date.now(),
		speed: {
			x: 0,
			y: 0,
		},
	};

	thumbsInitialTouch = { ...thumbsLastTouch };

	doc.off('touchmove.gallery').on('touchmove.gallery', onThumbnailsTouchMove);
	doc.off('touchend.gallery').on('touchend.gallery', onThumbnailsTouchEnd);
}

function setEvents() {
	$(document).on('keydown.gallery', (e) => {
		switch (e.key) {
		case 'ArrowDown':
			goIfPossible(1);
			break;
		case 'ArrowUp':
			goIfPossible(-1);
			break;
		default:
			break;
		}
	});

	$window.on('orientationchange', () => {
		resizeGallery();
		GalleryHelper.scrollToId(currentGalleryIndex, 0);
	});

	thumbnails.off('click.gallery').on('click.gallery', (e) => {
		GalleryHelper.scrollToId(e.currentTarget.getAttribute('data-gallery-id'), 0.9);
	});

	thumbnailsCtn.add(galleryImage).off('wheel.gallery').on('wheel.gallery', (e) => {
		const modifier = e.originalEvent.deltaMode === DOM_DELTA_LINE ? 30 : 1;
		TweenMax.killTweensOf(scrollPosition);
		GalleryHelper.scrollByOffset(-e.originalEvent.deltaY * modifier);
		if (wheelTimeout) {
			clearTimeout(wheelTimeout);
		}
		wheelTimeout = setTimeout(() => {
			GalleryHelper.goToClosest();
		}, 300);
	});

	thumbnailsInner.off('touchstart.gallery').on('touchstart.gallery', onThumbnailsTouchStart);

	scrubHandle.off('mousedown.gallery').on('mousedown.gallery', (e) => {
		e.preventDefault();
		const scrubOffset = scrubber.offset().top;

		$window.on('mousemove.gallery', (evt) => {
			e.preventDefault(e);

			const percent = (evt.clientY - scrubOffset) / scrubH;
			const offset = Math.min(1, Math.max(0, percent));

			GalleryHelper.scrub(offset, 0);
		});

		$window.on('mouseup.gallery', () => {
			$window.off('mousemove.gallery mouseup.gallery');

			GalleryHelper.goToClosest();
		});
	});

	let itemInitialTouch = null;
	let itemLastTouch = null;
	galleryImage.off('touchstart.gallery').on('touchstart.gallery', (e) => {
		itemInitialTouch = {
			x: e.touches[0].pageX,
			y: e.touches[0].pageY,
		};

		doc.off('touchmove.gallery').on('touchmove.gallery', (evt) => {
			evt.preventDefault();

			itemLastTouch = {
				x: evt.touches[0].pageX,
				y: evt.touches[0].pageY,
			};
		});

		doc.off('touchend.gallery').on('touchend.gallery', () => {
			if (itemLastTouch) {
				let offset;
				if (isMobile) {
					offset = itemLastTouch.x - itemInitialTouch.x;
				} else {
					offset = itemLastTouch.y - itemInitialTouch.y;
				}

				if (offset >= 20) {
					goIfPossible(-1);
				} else if (offset <= -20) {
					goIfPossible(1);
				}

				itemLastTouch = null;
			}

			doc.off('touchmove.gallery');
			doc.off('touchend.gallery');
		});
	});

	scrubNext.off('click.gallery').on('click.gallery', () => {
		goIfPossible(1);
	});

	scrubPrev.off('click.gallery').on('click.gallery', () => {
		goIfPossible(-1);
	});
	
	GalleryHelper.scrollToId(currentGalleryIndex);
}

/**
 * Ensures that every image is the same height throughout the experience.
 * 
 * Sets the appropriate events for the resolution.
 */
function resizeGallery() {
	isMobile = $window.width() <= MOBILE_TRESHOLD;
	singleThumbHeight = thumbnails.first().outerHeight();
	singleThumbWidth = thumbnails.first().outerWidth();
	scrubH = scrubber.height() - scrubHandle.height();
	thumbsHeight = singleThumbHeight * thumbnails.length;
	thumbsWidth = singleThumbWidth * thumbnails.length;
	resizeGalleryItem();
}

function resizeGalleryItem() {
	const item = galleryItem;
	const size = photos[currentGalleryIndex].size;
	const img = $('[data-image], [data-low-res]', item);
	const imgWrap = $('.img-wrap', item);
	const availWidth = item.width();
	const availHeight = $window.height() - parseInt(item.css('padding-top'), 10) - parseInt(item.css('padding-bottom'), 10);
	imgWrap.height('auto').width('auto');
	img.height('auto');

	const wrapRatio = availWidth / availHeight;

	if (wrapRatio > 1) {
		if (size.ratio > wrapRatio) {
			img.width(availHeight);
			img.height(availHeight / size.ratio);
		} else {
			img.width(availHeight * size.ratio);
			img.height(availHeight);
		}
	} else {
		if (size.ratio > wrapRatio) { // eslint-disable-line no-lonely-if
			img.width(availWidth);
			img.height(availWidth / size.ratio);
		} else {
			img.width(availWidth * size.ratio);
			img.height(availWidth);
		}
	}

}

function buildThumbnails() {
	orderedPhotos.forEach((key) => {
		const photo = photos[key];
		thumbnailsInner.append(`<div class="item" data-thumb data-gallery-id="${photo.id}">
			<img src="${photo.thumb}" alt="${photo.title} - ${photo.location}" />
		</div>`);
	});
	thumbnails = $('[data-thumb]', thumbnailsCtn);
}


export default {
	init() {
		lockBody();

		intro = $('.gallery-intro');
		introCTA = $('.know-more');

		gallery = $('[data-gallery]');
		galleryItem = $('[data-gallery-item]', gallery);
		galleryImage = $('[data-gallery-image]', gallery);

		slider = $('[data-slider]', gallery);
		thumbnailsCtn = $('[data-thumbnails-ctn]', gallery);
		thumbnailsInner = $('[data-thumbnails]', thumbnailsCtn);

		scrubber = $('[data-scrubber]');
		scrubHandle = $('[data-scrubhandle]', scrubber);
		scrubNext = $('[data-scrubnext]', scrubber);
		scrubPrev = $('[data-scrubprev]', scrubber);

		$.ajax({
			url: `${ns.root}?t=gallery&e=json`,
			dataType: 'json',
			success: (data) => {
				orderedPhotos = [];
				photos = data.reduce((c, v) => {
					orderedPhotos.push(v.id);
					c[v.id] = v;
					return c;
				}, {});

				const firstId = ns.default_gallery_index ? ns.default_gallery_index : orderedPhotos[0];
				currentGalleryIndex = photos[firstId].id;
				DEFAULT_INDEX = currentGalleryIndex;

				buildThumbnails();
				
				if (!localStorage.getItem('seenIntro') || !Boolean(localStorage.getItem('seenIntro'))) {
					showIntro();
				}
				
				introCTA.on('click', showIntro);
				intro.on('click', () => {
					intro.off('click');

					hideIntro();
					localStorage.setItem('seenIntro', true);
					intro.on('click', hideIntro);
				});

				function onStateChange(e) {
					if (!e.state) {
						PaneHelper.isOpened() && PaneHelper.closePane();
					} else {
						const p = PaneHelper.isOpened() ? PaneHelper.closePane() : Promise.resolve();
						p.then(() => {
							GalleryHelper.setDefaultState();
						});
					}
				}

				$window.on('resize.gallery', resizeGallery).trigger('resize');
				window.addEventListener('popstate', onStateChange);

				setEvents();
			},
			error: (e, status, msg) => {
				console.error(status, msg);
			},
		});
		
	},
};
