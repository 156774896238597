'format es6';
'use strict';

import $ from 'jquery';

function init() {

	const page = $('.temporaire');

	if (page.length === 0) return;

	console.log('inside');

	// const player_instance = new window.RadioCanada.player('player', {
	// 	appCode: 'medianet',
	// 	idMedia: '7701332',
	// 	params: {
	// 		width: '100%',
	// 		height: '100%',
	// 		autoPlay: false,
	// 		lang: 'fr',
	// 	},
	// });

	// $('[data-play]').on('click', (e) => {
	// 	e.preventDefault();
	// 	$('[data-play]').addClass('playing');
	// 	player_instance.play();
	// });
}

export default {
	init,
};
