'format es6';
'use strict';

import $ from 'jquery';
import Promise from 'Promise';
try{require('source-map-'+'support').install();}catch(e){}

const name = 'photos365';

const ns = window[name] = (window[name] || {});

ns.window = $(window);

window.requestAnimFrame = (() => {
	return window.requestAnimationFrame ||
		window.webkitRequestAnimationFrame ||
		window.mozRequestAnimationFrame ||
		function(callback) {
			window.setTimeout(callback, 1000 / 60);
		};
})();

export default ns;

