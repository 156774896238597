'format es6';
'use strict';

import Promise from 'Promise';
import ns from 'ns';
import { TweenMax } from 'gsap';

function init() {
	const $w = $(window);
	const google = window.google;

	function offsetPoint(latlng, offsetx, offsety, map) {
		const scale = map.getZoom() ** 2;

		const worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
		const pixelOffset = new google.maps.Point((offsetx / scale) || 0, (offsety / scale) || 0);

		const worldCoordinateNewCenter = new google.maps.Point(
			worldCoordinateCenter.x - pixelOffset.x,
			worldCoordinateCenter.y + pixelOffset.y,
		);

		return map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
	}

	function CustomMarker(bounds, map, marker) {
		this.bounds = bounds;
		this.setMap(map);
		this.infos = marker;

		this.onPanelOpen = null;
		this.onPanelClick = null;

		this.isReady = new Promise((resolve, reject) => {
			this.readyCallback = () => {
				resolve();
			}
		});

		this.onClickMarker = (e) => {
			e.stopPropagation();
			e.preventDefault();

			this.panel.classList.toggle('active');

			const offset = $w.width() >= 992 ? 0 : -$w.height() / 3;
			if (this.panel.classList.contains('active')) {
				map.panTo(offsetPoint(bounds.getNorthEast(), 0, offset, this.map));
				this.onPanelOpenCallback();
			}
		};

		this.onClickPanel = (e) => {
			e.stopPropagation();
			e.preventDefault();

			this.setVisited();

			this.onPanelClickCallback();
		};

		this.setVisited = () => {
			const num = this.div.parentNode.querySelector('.num');
			if (num) num.classList.add('visited');
		};
	}

	CustomMarker.prototype = new google.maps.OverlayView();

	CustomMarker.prototype.onAdd = function onAdd() {
		const div = document.createElement('div');
		div.className = 'ping';
		this.getPanes().overlayMouseTarget.appendChild(div);

		const inner = document.createElement('div');
		inner.classList.add('inner');

		const span = document.createElement('span');
		inner.appendChild(span);

		div.appendChild(inner);

		const num = document.createElement('div');

		if (ns.isInVacation || !this.infos.isLast) {
			num.classList.add('num');
			div.appendChild(num);
			num.innerText = this.infos.number;
		} else {
			num.classList.add('current');
			inner.classList.add('current-pin');
			div.appendChild(num);
			num.innerText = 'Présentement ici';
		}

		this.addInfos(div);

		div.classList.add('custom-marker');
		this.div = div;
		this.readyCallback && this.readyCallback();
		// google.maps.event.addDomListener(div, 'click', this.onClickMarker);
		div.addEventListener('click', this.onClickMarker);
	};

	CustomMarker.prototype.onPanelOpenCallback = function onPanelOpen() {
		if (this.onPanelOpen) this.onPanelOpen(this);
	};

	CustomMarker.prototype.onPanelClickCallback = function onPanelClick() {
		if (this.onPanelClick) this.onPanelClick(this);
	};

	CustomMarker.prototype.closePanel = function closePanel() {
		this.panel.classList.remove('active');
	};

	CustomMarker.prototype.addInfos = function addInfos(div) {
		const panel = document.createElement('div');
		panel.classList.add('panel');

		const imgWrap = document.createElement('div');
		imgWrap.classList.add('img-wrap');

		const image = document.createElement('img');
		image.setAttribute('src', this.infos.picture);
		imgWrap.appendChild(image);
		panel.appendChild(imgWrap);

		const text = document.createElement('div');
		text.classList.add('text');
		panel.appendChild(text);

		const title = document.createElement('div');
		title.classList.add('title');
		title.innerText = this.infos.title;
		text.appendChild(title);

		const region = document.createElement('div');
		region.classList.add('region');
		region.innerText = this.infos.region;
		text.appendChild(region);

		this.panel = panel;
		google.maps.event.addDomListener(panel, 'click', this.onClickPanel);

		div.appendChild(panel);
	};

	CustomMarker.prototype.draw = function draw() {
		const overlayProjection = this.getProjection();
		const sw = overlayProjection.fromLatLngToDivPixel(this.bounds.getSouthWest());
		const ne = overlayProjection.fromLatLngToDivPixel(this.bounds.getNorthEast());
		const div = this.div;
		TweenMax.set(div, { x: sw.x, y: ne.y });
	};

	return CustomMarker;
}

export default {
	init,
};
