'format es6';
'use strict';

const WP_KEY = 'Windows Phone';
const ANDROID_KEY = 'Android';
const IOS_KEY = 'iOS';
const DEFAULT_KEY = 'unkwown';

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = () => {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;

	// Windows Phone must come first because its UA also contains "Android"
	if (/windows phone/i.test(userAgent)) {
		return WP_KEY;
	}

	if (/android/i.test(userAgent)) {
		return ANDROID_KEY;
	}

	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return IOS_KEY;
	}

	return DEFAULT_KEY;
};

export const isAndroid = () => getMobileOperatingSystem() === ANDROID_KEY;
export const isWindowsPhone = () => getMobileOperatingSystem() === WP_KEY;
export const isIOS = () => getMobileOperatingSystem() === IOS_KEY;
