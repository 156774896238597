'format es6';
'use strict';

import Promise from 'Promise';

export default function imagesloaded(div) {
	const imgs = div.find('img');
	const totalImages = imgs.length;
	let loadedImagesCount = 0;

	return new Promise((resolve) => {
		if (imgs.length === 0 || imgs.prop('complete')) {
			resolve(div);
		} else {
			imgs.on('load', () => {
				loadedImagesCount++;
				if (loadedImagesCount === totalImages) {
					resolve(div);
				}
			});
		}
	});
}
