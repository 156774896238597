'format es6';
'use strict';

import $ from 'jquery';
import { docReady } from './utils/docReady';
import { isMobile } from './utils/isMobile';
import ShareSnippet from './ShareSnippet';
// import skrollr from '../vendor/skrollr';

import Panable from './Panable';
import Gallery from './Gallery';
import Map from './map/Map';
import PageTemporaire from './PageTemporaire';
import Menu from './Menu';
import Parallax from './Parallax';
import Modals from './Modals';

docReady.then(() => {
	console.clear();

	ShareSnippet.init();

	const shareBtn = $('[data-share-btn]');
	shareBtn.on('click', (e) => {
		$(e.currentTarget).toggleClass('opened');
	});

	Map.init();

	if ($('.gallery').length > 0) {
		Gallery.init();
		Panable.init();
	}

	PageTemporaire.init();

	Menu.init();

	Parallax.init();
	Modals.init();
});
