'format es6';
'use strict';

import $ from 'jquery';
import ns from 'ns';
import Promise from 'Promise';
import gsap, { TweenMax } from 'gsap';
import docReady from '../utils/docReady';
import WhatsNearby from '../../vendor/WhatsNearby';

import CoordMapType from './CoordMapType';
import CustomMarkerGenerator from './CustomMarker';

import AlbumPage from './AlbumPage';

import { scrollTo } from '../utils/scrollTo';

let google;

function init() {
	google = window.google;

	const mapWrapper = $('[data-map-wrapper]');
	if (mapWrapper.length === 0) return;

	const body = $('body');
	const albumPage = AlbumPage.init();
	const CustomMarker = CustomMarkerGenerator.init();
	const mapCtn = $('[data-map]');

	const LAT_MAX = 85;
	const LAT_MIN = -85;

	const modal = $('.overlay', mapWrapper);
	
	let lastValidCenter = null;

	let markers = null;

	const knowMoreBtn = $('[data-map-know-more]');

	function onPanelClick(marker) {
		albumPage.load(marker.infos.id).then(() => {
			TweenMax.to(mapCtn, 0.6, { scale: 1.5, ease: gsap.Cubic.easeIn });
			body.addClass('opened');

			const title = marker.infos.page_title + ns.default_title_suffix;
			window.history.pushState(marker.infos, title, marker.infos.link);
			document.title = title;
		});
		scrollTo(0);
	}

	function onAlbumClose() {
		TweenMax.to(mapCtn, 0.6, { scale: 1, ease: gsap.Cubic.easeOuy, delay: 0.1 });
		body.removeClass('opened');
		window.history.replaceState(null, ns.default_title, ns.default_page_link);
		document.title = ns.default_title;
	}

	function onStateChange(e) {
		if (!e.state) {
			if (albumPage) albumPage.close();
		}
	}

	// console.log('map %');
	window.addEventListener('popstate', onStateChange);

	albumPage.onAlbumClose = onAlbumClose;

	const map = mapCtn.whatsnearby({
		mapType: google.maps.MapTypeId.SATELLITE,
		disableDefaultUI: true,
		width: '100%',
		height: '100%',
		zoom: 4,
		minZoom: 4,
		lat: 47.945354, 
		lng: -0.672686,
		placeMainMarker: false,
	}).data('whatsnearby').map;

	let hasInited = false;

	const initMap = () => {
		if (!hasInited) {
			knowMoreBtn.addClass('active');

			Promise.all(markers.map(m => m.isReady)).then(() => {

				setTimeout(() => {
					const mDivs = markers.map((m) => {
						if (ns.default_album_page_index && m.infos.id === ns.default_album_page_index) {
							m.setVisited();
						}
						return m.div;
					});
					TweenMax.staggerTo(mDivs, 0.3, { opacity: 1 }, 0.2);
				}, 500);
			});
			
			hasInited = true;
		}
	};

	google.maps.event.addListenerOnce(map, 'idle', () => {
		if (ns.default_album_page_index) {
			albumPage.load(ns.default_album_page_index);
		}

		map.setCenter(lastValidCenter);

		// if (~document.cookie.indexOf('map_overlay')) {
		initMap();
		// }

		google.maps.event.addListener(map, 'center_changed', () => {
			const bounds = map.getBounds();
			if (!bounds) return;

			const ne = bounds.getNorthEast();
			const sw = bounds.getSouthWest();

			if (ne.lat() >= LAT_MAX || sw.lat() <= LAT_MIN) {
				map.setCenter(lastValidCenter);
			}

			lastValidCenter = map.getCenter();
		});
	});

	const loadMarkers = new Promise((resolve, reject) => {
		$.ajax({
			url: ns.album_json_url,
			dataType: 'json',
			success: data => resolve(data),
			error: error => reject(error),
		});
	});

	map.overlayMapTypes.insertAt(0, new CoordMapType(new google.maps.Size(256, 256)));

	function onClickMap() {
		markers.forEach((marker) => {
			marker.closePanel();
		});

		knowMoreBtn.addClass('active');
	}

	mapWrapper.on('click', onClickMap);
	// google.maps.event.addListener(map, 'click', onClickMap);

	function onPanelOpen(opened) {
		markers.forEach((marker) => {
			if (opened.id !== marker.id) {
				marker.closePanel();
			}
		});

		knowMoreBtn.removeClass('active');
	}

	modal.on('click', () => {
		modal.fadeOut();
		document.cookie = 'map_overlay=1';

		initMap();
	});

	knowMoreBtn.on('click', () => {
		modal.fadeIn();
		knowMoreBtn.removeClass('active');
	});

	loadMarkers.then((albums) => {
		const len = albums[0].length;

		const markerBounds = new google.maps.LatLngBounds();

		markers = albums[0].map((marker, i) => {
			marker.isLast = i === len - 1;
			const lat = marker.position.lat;
			const lng = marker.position.lng;
			const swBound = new google.maps.LatLng(lat, lng);
			const neBound = new google.maps.LatLng(lat, lng);
			const bounds = new google.maps.LatLngBounds(swBound, neBound);
			const customMarker = new CustomMarker(bounds, map, marker);

			customMarker.onPanelOpen = onPanelOpen;
			customMarker.onPanelClick = onPanelClick;
			customMarker.id = i;

			markerBounds.extend(swBound);

			return customMarker;
		});

		console.log('move');
		lastValidCenter = markerBounds.getCenter();

		// map.setCenter(markerBounds.getCenter());
		// lastValidCenter = markerBounds.getCenter();
		// console.log(markerBounds.getCenter().toString());
	});
}

export default {
	init,
};
