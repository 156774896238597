'format es6';
'use strict';

import $ from 'jquery';

export const SNIPPET_SELECTOR = '[data-share-snippet]';
const SNIPPET_DATA_SELECTOR = '[data-share]';
const SNIPPET_ID_DATA = 'snippet-id';
const EVENT_NAMESPACE = 'lagrange';

function createSnippet(snippet, useLocation = false) {
	const _snippet = $(snippet);
	let _useLocation = useLocation;

	let data = $(SNIPPET_DATA_SELECTOR).filter(`[data-${SNIPPET_ID_DATA}="${_snippet.data(SNIPPET_ID_DATA)}"]`).html();
	if (data) {
		data = JSON.parse(data);
	} else {
		_useLocation = true;
	}

	const facebookBtn = $('[data-facebook]', _snippet);
	const twitterBtn = $('[data-twitter]', _snippet);
	const linkedinBtn = $('[data-linkedin]', _snippet);

	function updateLocationData() {
		data = (_useLocation) ? { 
			url: window.location.href,
			caption: '',
			name: '',
			description: '',
		} : data;
	}

	facebookBtn.on(`click.${EVENT_NAMESPACE}.share_snippet`, (e) => {
		e.preventDefault();
		e.stopPropagation();

		updateLocationData();

		window.FB.ui({
			method: 'feed',
			link: data.url,
			caption: data.caption,
			name: data.name,
			picture: data.picture,
			description: data.description,
		});
	});

	twitterBtn.on(`click.${EVENT_NAMESPACE}.share_snippet`, (e) => {
		e.preventDefault();
		e.stopPropagation();

		updateLocationData();

		const twitterText = data.twitter || data.description;
		const url = `https://twitter.com/intent/tweet/?url=${encodeURI(data.url)}&text=${encodeURIComponent(twitterText)}`;
		window.open(url, 'Partager', 'width=560,height=250');
	});

	linkedinBtn.on(`click.${EVENT_NAMESPACE}.share_snippet`, (e) => {
		e.preventDefault();
		e.stopPropagation();

		updateLocationData();

		const url = `https://www.linkedin.com/cws/share?url=${data.url}`;
		window.open(url, 'Partager', 'width=520,height=570');
	});
}

export default {
	init(container = document, useLocation = false) {
		$(SNIPPET_SELECTOR, container).each((i, snippet) => {
			createSnippet(snippet, useLocation);
		});
	},
};
