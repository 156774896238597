'format es6';
'use strict';

function CoordMapType(tileSize) {
	this.tileSize = tileSize;
}

CoordMapType.prototype.getTile = function(coord, zoom, ownerDocument) {
	const div = ownerDocument.createElement('div');
	div.style.width = `${this.tileSize.width}px`;
	div.style.height = `${this.tileSize.height}px`;
	div.style.backgroundColor = '#133054';
	div.style.opacity = 0.5;
	return div;
};

export default CoordMapType;
