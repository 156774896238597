'format es6';
'use strict';

import $ from 'jquery';
import gsap, { TweenMax } from 'gsap';
import ns from 'ns';
import imagesloaded from '../utils/simpleImagesLoaded';
import Promise from 'Promise';
import { scrollToElem } from '../utils/scrollTo';

import Parallax from '../Parallax';

function init() {
	const $w = $(window);
	const overlay = $('[data-album-image-overlay]');
	const closeOverlay = $('[data-close-overlay-button]');
	let content;

	function animateIn() {
		closeOverlay.addClass('active');
		const left = content.find('.left');
		const right = content.find('.right');

		overlay.show();

		if ($w.width() < 992) {
			TweenMax.from(left, 0.3, { x: '-110%', ease: gsap.Cubic.easeOut, delay: 0.3 });
			TweenMax.from(right, 0.3, { x: '110%', ease: gsap.Cubic.easeOut, delay: 0.3 });
		} else {
			TweenMax.from(left, 0.3, { y: '-110%', ease: gsap.Cubic.easeOut, delay: 0.3 });
			TweenMax.from(right, 0.3, { y: '110%', ease: gsap.Cubic.easeOut, delay: 0.3 });
		}

		$('[data-scrolldown]', content).on('click', (e) => {
			e.preventDefault();
			scrollToElem($('.images', content), { container: content.parent() });
		});
	}

	function load(id) {
		return new Promise((resolve) => {
			$.ajax({
				url: `${ns.root}/?t=album&i=${id}&json=1`,
				success: (data) => {
					content = $(data).find('[data-content] .album-page');
					content.removeClass('in');
					overlay.empty();
					overlay.append(content);
					imagesloaded(content).then(() => {
						animateIn();

						Parallax.init(overlay);

						resolve();
					});

					overlay.hide();
				},
				error: msg => console.error(msg),
			});
		});
	}

	function animateOut(onClose) {
		closeOverlay.removeClass('active');

		const scroll = {
			y: overlay.scrollTop(),
		};

		TweenMax.to(scroll, 0.3, {
			y: 0,
			ease: gsap.Sine.easeInOut,
			onUpdate: () => overlay.scrollTop(scroll.y),
		});

		TweenMax.delayedCall(0.2, () => {
			const left = content.find('.left');
			const right = content.find('.right');

			onClose();

			if ($w.width() < 992) {
				TweenMax.to(left, 0.3, { x: '110%', ease: gsap.Cubic.easeIn, delay: 0.3 });
				TweenMax.to(right, 0.3, { x: '-110%', ease: gsap.Cubic.easeIn, delay: 0.3, onComplete: () => overlay.hide() });
			} else {
				TweenMax.to(left, 0.3, { y: '110%', ease: gsap.Cubic.easeIn, delay: 0.3 });
				TweenMax.to(right, 0.3, { y: '-110%', ease: gsap.Cubic.easeIn, delay: 0.3, onComplete: () => overlay.hide() });
			}
		});
	}

	function AlbumPage() {
		this.init = () => {
			closeOverlay.on('click', this.onCloseAlbumCallback);
		};

		this.close = () => {
			if (this.onAlbumClose && closeOverlay.hasClass('active')) {
				animateOut(this.onAlbumClose);
			}
		};

		this.onCloseAlbumCallback = () => {
			this.close();
		};

		this.load = load;

		this.init();
	}

	return new AlbumPage();
}

export default {
	init,
};
