'use strict';
'format es6';

const bkp_define = window.define;
const bkp_jquery = window.jQuery;

// window.define = define = false;
// window.jQuery = $ = require('jquery');

const imagesloaded = require('imagesloaded');

// window.jQuery = bkp_jquery;
// window.define = define = bkp_define;

export default imagesloaded;

