import $ from 'jquery';
import { TweenMax } from 'gsap';

const $window = $(window);

function MoveItItem(el, scrollElem) {
	this.el = $(el);
	this.scrollElem = scrollElem;
	this.speed = parseInt(this.el.attr('data-scroll-speed'));

	this.ticking = false;
	this.lastScrollY = 0;
}

$.fn.moveIt = function moveIt(scrollElem = $window) {
	const instances = [];

	$(this).each((i, el) => {
		instances.push(new MoveItItem($(el), scrollElem));
	});

	/* function that update instances */
	function onScroll() {
		const scrollTop = scrollElem.scrollTop();
		instances.forEach((inst) => {
			inst.update(scrollTop);
		});
	}

	scrollElem.on('scroll.tb', onScroll);
};

MoveItItem.prototype.update = function update(scrollTop) {
	if (!this.ticking) {
		this.ticking = true;

		window.requestAnimationFrame(() => {
			const element = this.el;

			const DURATION = 0.5;
			const obj = this.el.offset().top;

			if (Math.abs(this.speed) === 1) {
				TweenMax.set(element, { y: -this.lastScrollY, force3D: true });
			} else {
				TweenMax.to(element, DURATION, {
					y: (obj - this.lastScrollY) / this.speed,
				});
			}

			this.ticking = false;
		});

		this.lastScrollY = scrollTop;
	}
};

export default {
	init(ctx) {
		const SCROLL_ELEMENT = $('[data-scroll-speed]', ctx);

		if (window.matchMedia('(min-width: 991px)').matches) {
			if (SCROLL_ELEMENT.length) SCROLL_ELEMENT.moveIt(ctx);
		}
	},
};
