'format es6';
'use strict';

import $ from 'jquery';
import gsap, { TweenMax } from 'gsap';

let menuInner;
let links;
let pos;

let video;

const setupLinks = () => {
	const $w = $(window);

	links = $('.link-2, .link-3, .link-4', menuInner);
	pos = $('.pos-2, .pos-3, .pos-4');

	// links.not('.link-5').on('mouseover', (e) => {
	// 	const current = $(e.currentTarget);

	// 	links.not(current).addClass('fade');
	// 	current.removeClass('fade');
	// });

	links.on('mouseout', (e) => {
		links.removeClass('fade');
	});

	const ratios = [1, 1.8, 1.4];
	menuInner.on('mousemove', (e) => {
		const vw = $w.width();
		const ratioLength = ratios.length;
		if (vw >= 992) {
			const amount = (15 / 1920) * vw;
			pos.each((i, el) => {
				const off = menuInner.offset();
				const w = menuInner.outerWidth();
				const h = menuInner.outerHeight();

				const dx = ((e.clientX - off.left - (w / 2)) / w) * amount * ratios[i % ratioLength];
				const dy = ((e.clientY - off.top - (h / 2)) / h) * amount * ratios[i % ratioLength];

				// console.log(off, w, h, e.clientX, e.clientY, dx, dy);
				TweenMax.to(el, 0.3, { x: dx, y: dy });
			});
		} else {
			TweenMax.set(pos, { x: 0, y: 0 });
		}
	});
};

const setupMenuButton = () => {
	const menuButton = $('.btn-menu');
	const body = $('body');
	const topMenu = $('.top-menu');
	const shareBtn = $('[data-share-btn]');

	const player = $('#player');

	menuButton.on('click', () => {
		menuButton.toggleClass('active');
		topMenu.toggleClass('active');
		shareBtn.removeClass('opened');
		
		if (topMenu.hasClass('active')) {
			body.addClass('opened');

			if (video) {
				video.play();
			}
		} else {
			body.removeClass('opened');

			if (video) {
				video.pause();
			}
		}

		if (player.length !== 0) {
			const iframe = player[0].contentWindow;
			iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
		}
	});

	menuButton.find('a').on('click', e => e.preventDefault());

	menuInner = $('.menu-inner');
	if (menuInner.length >= 1) setupLinks();
};

function init() {
	setupMenuButton();

	video = $('.top-menu video').get(0);

	if (video) {
		video.pause();
	}
}

export default {
	init,
};
