'format es6';
'use strict';

import $ from 'jquery';
import Promise from 'Promise';

import gsap, { TweenMax } from 'gsap';
import ns from 'ns';
import { isAndroid } from './utils/detectOS';
import imagesloaded from './shims/imagesloaded';

import detectSwipe from '../vendor/jquery.detectSwipe';

import { GalleryHelper, MOBILE_TRESHOLD } from './Gallery';
import ShareSnippet from './ShareSnippet';

import { setOverlay } from './Modals';


let galleryImages;

const $window = $(window);
const LERP_FACTOR = 0.025;

let $currentPane;
let $root;
let animationFrame;
let paneProps = {
	lastX: 0,
	lastY: 0,
};

const scale = isAndroid() ? 0.5 : 10;

//=============================================================================
// Animation helpers
//=============================================================================

function map(num, in_min, in_max, out_min, out_max) {
	return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

function lerp(v0, v1, t) {
	return (1 - t) * v0 + t * v1;
}


//=============================================================================
// Events handlers
//=============================================================================

function deviceMotionHandler(_e) {
	const e = _e.originalEvent;
	
	const deviceOri = (window.orientation === 0 || window.orientation === 180) ? 'vert' : 'hori';

	const invert = -1;
	if ((!paneProps.isLarge && deviceOri === 'hori') || (paneProps.isLarge && deviceOri === 'hori')) {
		//isLarge && hori
		//!isLarge && hori
		paneProps.x += e.rotationRate.alpha * e.interval * invert * scale;
		paneProps.y += -e.rotationRate.beta * e.interval * invert * scale;
	} else {
		// isLarge && vert
		// !isLarge && vert
		paneProps.x += -e.rotationRate.beta * e.interval * invert * scale;
		paneProps.y += -e.rotationRate.alpha * e.interval * invert * scale;
	}
}

function mouseMoveHandler(_e) {
	const e = _e.originalEvent;
	paneProps.y = e.clientY;
	paneProps.x = e.clientX;
}

function orientationChangeHandler() {
	setupPaneProps();
}

function removeEvents() {
	$window.off('devicemotion.gallery');
	$currentPane.off('mousemove.gallery');
	$window.off('orientationchange.gallery');
}

function arrowsClickHandler(e) {
	e.preventDefault();
	const d = parseInt($(e.currentTarget).data('direction'), 10);
	nextPane(d);
}

function onSwipe(e, dir) {
	if (dir === 'left' || dir === 'right') {
		const direction = dir === 'left' ? 1 : -1;

		nextPane(direction);
	}
}

function nextPane(direction) {
	if (GalleryHelper.canGoTo(direction)) {
		// Load the next pane before closing so it loads while animating out the current one.
		const id = GalleryHelper.getIdFromDirection(direction);
		const loadPromise = loadPane(id);

		closePane().then(() => {
			// Once animated out, move the gallery
			const galleryPromise = GalleryHelper.scrollToId(id);

			// Once the gallery moved and the next pane are loaded, animate it in.
			Promise.all([galleryPromise, loadPromise]).then((data) => {
				const $pane = data[1];
				$pane.data('btn', $(`[data-pane-btn][data-pane-id=${id}]`));
				animateInPane($pane);
			});
		});
	}
}


function blockPanning() {
	$window.off('devicemotion.gallery', deviceMotionHandler);	
	$currentPane.off('mousemove.gallery', mouseMoveHandler);
	// $window.off('orientationchange.gallery', orientationChangeHandler);
}

function allowPanning() {
	$window.on('devicemotion.gallery', deviceMotionHandler);	
	$currentPane.on('mousemove.gallery', mouseMoveHandler);
	// 
}

function infoBtnHandler(e) {
	e.stopPropagation();
	e.preventDefault();
	blockPanning();

	const $infos = $('[data-infos]', $currentPane);
	const $overlay = $('[data-overlay]', $currentPane);
	
	$infos.addClass('active');
	$overlay.addClass('active');

	$infos.add($overlay).off('click.gallery').on('click.gallery', (_e) => {
		_e.preventDefault();
		_e.stopPropagation();

		$infos.removeClass('active');
		$overlay.removeClass('active');
		allowPanning();
	});
}

/**
 * Removes events, cancels the animationFrame and animates the pane out.
 * 
 * @param {Event} [e] Only there if function is called from a click.
 * @returns {Promise} animateOutPane()
 */
function closePane(e) {
	GalleryHelper.setDefaultState();
	
	if (e) {
		e.stopPropagation();
	}

	if ($currentPane) {
		const $closeBtn = $('[data-close-pane]', $currentPane);
		$closeBtn.off('click.gallery');

		removeEvents();
	}

	cancelAnimationFrame(animationFrame);

	return animateOutPane($currentPane);
}


//=============================================================================
// Pan action
//=============================================================================

/**
 * Moves the image in the viewport with lerp and map
 */
function loop() {
	if (paneProps.lastY !== paneProps.y && !paneProps.isLarge) {
		let newY = map(paneProps.y, 0, paneProps.pane.clientHeight, 0, -(paneProps.image.clientHeight - paneProps.pane.clientHeight));
		const maxY = 0;
		const minY = -Math.abs(paneProps.pane.clientHeight - paneProps.image.clientHeight);
		newY = lerp(paneProps.lastY, newY, LERP_FACTOR);
		newY = newY > maxY ? maxY : (newY < minY ? minY : newY);
		TweenMax.set(paneProps.image, { y: newY, x: 0 });
		paneProps.lastY = newY;
	}

	if (paneProps.lastX !== paneProps.x && paneProps.isLarge) {
		let newX = map(paneProps.x, 0, paneProps.pane.clientWidth, 0, -(paneProps.image.clientWidth - paneProps.pane.clientWidth));
		const maxX = 0;
		const minX = -Math.abs(paneProps.pane.clientWidth - paneProps.image.clientWidth);
		newX = lerp(paneProps.lastX, newX, LERP_FACTOR);
		newX = newX > maxX ? maxX : (newX < minX ? minX : newX);
		TweenMax.set(paneProps.image, { x: newX, y: 0 });
		paneProps.lastX = newX;
	}

	animationFrame = requestAnimationFrame(loop);
}

/**
 * Sets up the pane properties
 */
function setupPaneProps(loadedPane) {
	if (loadedPane) {
		$currentPane = loadedPane;
	}

	const $image = $currentPane.find('[data-movable]');
	$image.removeClass('larger higher');

	const sw = isAndroid() ? window.screen.width : window.innerWidth;
	const sh = isAndroid() ? window.screen.height : window.innerHeight;

	const vw = $window.width() <= MOBILE_TRESHOLD ? sw : window.innerWidth;
	const vh = $window.height() <= MOBILE_TRESHOLD ? sh : window.innerHeight;

	// Scale the image so that it takes 100% of the viewport height, then check if it is
	// larger than the viewport width.
	const calcWidth = ($image[0].clientWidth * vh) / $image[0].clientHeight;
	const isLarge = calcWidth > vw;
	$image.addClass(isLarge ? 'larger' : 'higher');

	paneProps = {
		y: $currentPane[0].clientHeight / 2,
		x: $currentPane[0].clientWidth / 2,
		lastX: 0,
		lastY: 0,
		pane: $currentPane[0],
		image: $image[0],
		isLarge,
	};
}


//=============================================================================
// DOM setup
//=============================================================================

/**
 * Sets up the new pane as the $currentPane. Sets events on its buttons and 
 * all the other events for the paning action.
 * 
 * @param {JQuery} loadedPane 
 */
function activatePane() {
	$currentPane.off('click.gallery');

	const gImgWrap = galleryImages.find('.img-wrap');
	const loader = gImgWrap.siblings('.loader');
	gImgWrap.removeClass('loading');
	loader.removeClass('active');

	const $closeBtn = $('[data-close-pane]', $currentPane);
	const $infosBtn = $('[data-info-cta]', $currentPane);
	const $arrows = $('[data-arrow]', $currentPane);
	const shareBtn = $('[data-share-btn]', $currentPane);
	shareBtn.on('click', (e) => {
		e.stopPropagation();
		$(e.currentTarget).toggleClass('opened');
	});

	setOverlay($currentPane);

	$currentPane.on('swipe', onSwipe);

	ShareSnippet.init($currentPane);

	$arrows.on('click.gallery', arrowsClickHandler);
	$infosBtn.on('click.gallery', infoBtnHandler);
	$closeBtn.on('click.gallery', closePane);
	
	if ($window.width() >= MOBILE_TRESHOLD) {
		$currentPane.on('click.gallery', closePane);
	}

	$window.on('orientationchange.gallery', orientationChangeHandler);
	allowPanning();

	GalleryHelper.setGalleryState(GalleryHelper.getGalleryIndex());

	$arrows.each((i, el) => {
		const arr = $(el);
		if (!GalleryHelper.canGoTo(parseInt(arr.data('direction'), 10))) {
			arr.addClass('disabled');
			arr.off('click');
		}
	});
	
	cancelAnimationFrame(animationFrame);
	animationFrame = requestAnimationFrame(loop);
}

/**
 * Loads a pane and resolves with the jQuery object of it.
 * 
 * @param {Number} id The id of the pane (in the DB
 * @returns {Promise}
 */
function loadPane(id) {	
	const gImgWrap = galleryImages.find('.img-wrap');
	const loader = gImgWrap.siblings('.loader');
	gImgWrap.addClass('loading');
	loader.addClass('active');
	return new Promise((resolve, reject) => {
		$.ajax({
			url: `${ns.root}/?t=photo_gallery&i=${id}`,
			success(data) {
				resolve($(data));
			},
			error: message => reject(message),
		});
	});
}


//=============================================================================
// Pane animations (intro, outro)
//=============================================================================

/**
 * Animation of the pane closing. Removes the element of the DOM once completed.
 * 
 * @param {JQuery} $pane
 * 
 * @returns {Promise}
 */
function animateOutPane($pane) {
	return new Promise((resolve) => {
		TweenMax.to($pane, 0.3, {
			opacity: 0,
			scale: 1.1,
			ease: gsap.Cubic.easeIn,
			onComplete() {
				$pane.remove();
			},
		});
		TweenMax.to($pane.data('btn'), 0.3, { opacity: 1 });

		//GalleryHelper.setDefaultState();

		resolve();
	});
}

/**
 * Animation of the pane opening. Appends the element to the DOM.
 * Once the images are loaded and the animation is complete, activates the pane.
 * 
 * @param {JQuery} pane 
 */
function animateInPane($pane) {
	$root.append($pane);

	TweenMax.set($pane, { visibility: 'hidden' });
	$pane.imagesLoaded().done(() => {
		setupPaneProps($pane);
		TweenMax.set($pane, { visibility: 'visible' });

		TweenMax.to($pane.data('btn'), 0.3, { opacity: 0 });

		TweenMax.from($pane, 0.6, {
			opacity: 0,
			scale: 1.1,
			onComplete: activatePane,
			delay: 0.6,
			ease: gsap.Cubic.easeOut,
		});
		
		const ctas = $pane.find('.ctas');
		TweenMax.set(ctas, { visibility: 'visible' });
		TweenMax.from(ctas, 0.6, {
			y: 30,
			opacity: 0,
			ease: gsap.Cubic.easeOut,
			delay: 1.2,
		});
	});
}


export const PaneHelper = {
	closePane,
	isOpened() {
		return $currentPane && $currentPane.hasClass('active');
	},
};

export default {
	init() {
		$root = $('main');
		const paneBtns = $('[data-pane-btn]');
		galleryImages = $('.gallery-image');

		function paneBtnHandler(e) {
			paneBtns.off('click.gallery');

			const btn = $(e.currentTarget);
			const id = btn.data('pane-id');
			loadPane(id).then(($pane) => {
				$pane.data('btn', btn);
				animateInPane($pane);
				paneBtns.on('click.gallery', paneBtnHandler);
			});
		}

		paneBtns.on('click.gallery', paneBtnHandler);
	},
};
