/**
 * jquery.detectSwipe v2.1.3
 * jQuery Plugin to obtain touch gestures from iPhone, iPod Touch, iPad and Android
 * http://github.com/marcandre/detect_swipe
 * Based on touchwipe by Andreas Waltl, netCU Internetagentur (http://www.netcu.de)
 */

import $ from 'jquery';

$.detectSwipe = {
	version: '2.1.2',
	enabled: 'ontouchstart' in document.documentElement,
	preventDefault: true,
	threshold: 20,
};

let startX;
let startY;
let isMoving = false;

function onTouchEnd() {
	this.removeEventListener('touchmove', onTouchMove);
	this.removeEventListener('touchend', onTouchEnd);
	isMoving = false;
}

function onTouchMove(e) {
	if ($.detectSwipe.preventDefault) { e.preventDefault(); }
	if (isMoving) {
		const x = e.touches[0].pageX;
		const y = e.touches[0].pageY;
		const dx = startX - x;
		const dy = startY - y;
		let dir;
		if (Math.abs(dx) >= $.detectSwipe.threshold) {
			dir = dx > 0 ? 'left' : 'right';
		} else if (Math.abs(dy) >= $.detectSwipe.threshold) {
			dir = dy > 0 ? 'up' : 'down';
		}
		if (dir) {
			onTouchEnd.call(this);
			$(this).trigger('swipe', dir).trigger(`swipe${dir}`);
		}
	}
}

function onTouchStart(e) {
	if (e.touches.length === 1) {
		startX = e.touches[0].pageX;
		startY = e.touches[0].pageY;
		isMoving = true;
		this.addEventListener('touchmove', onTouchMove, false);
		this.addEventListener('touchend', onTouchEnd, false);
	}
}

function setup() {
	this.addEventListener && this.addEventListener('touchstart', onTouchStart, false);
}

function teardown() {
	this.removeEventListener('touchstart', onTouchStart);
}

$.event.special.swipe = { setup };

$.each(['left', 'up', 'down', 'right'], () => {
	$.event.special[`swipe${this}`] = {
		setup() {
			$(this).on('swipe', $.noop);
		},
	};
});
