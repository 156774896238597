import $ from 'jquery';
import { TweenMax } from 'gsap';

const $w = $(window);

export const setOverlay = (elem) => {
	const overlay = $(elem);
	const modal = overlay.find('.modal');
	overlay.off('mousemove.365photos.gallery').on('mousemove.365photos.gallery', (e) => {
		const vw = $w.width();
		if (vw >= 992 && modal.length === 1) {
			const amount = (15 / 1920) * vw;

			const off = overlay.offset();
			const w = overlay.outerWidth();
			const h = overlay.outerHeight();

			const dx = ((e.clientX - off.left - (w / 2)) / w) * amount * 1.5;
			const dy = ((e.clientY - off.top - (h / 2)) / h) * amount * 1.5;

			TweenMax.to(modal, 0.3, { x: dx, y: dy });
		} else {
			TweenMax.set(modal, { x: 0, y: 0 });
		}
	});
};

export default {
	init: () => {
		const overlays = $('.overlay');
		overlays.each((i, elem) => {
			setOverlay(elem);
		});
	},
};
